<template>
	<div class="appt-table">
		<CustomTableAppointment
			:total-pages="totalPages"
			:total-items="totalItems"
			:clinics="clinics"
			:services="services"
			:is-importing="isImporting"
			@create="create"
			@search="search"
			@import="importFile"
			@export="exportFile"
			@toggle-progress-bar="toggleProgressBar"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}</th
					>
				</tr>
			</template>
			<template #body>
				<template v-if="appointment && appointment.length">
					<tr
						v-for="(customer, index2) in appointment"
						:key="`${customer.id}-${index2}`"
						@click="goToAppt($event, customer.id)"
						@click.right="goToApptNewTab($event, customer.id)"
						@click.middle="goToApptNewTab($event, customer.id)"
					>
						<td scope="row" class="text-break text-pre-wrap appt-table__name">
							<div>
								<span>{{ $t("AppointmentFormTable.Username") }}: </span>
								{{ customer.customer && customer.customer.fullName }}
								<img
									v-if="customer.customer.deviceCount"
									v-lazy="phoneIcon"
									alt
									class="phoneIcon"
								/>
							</div>
							<div>
								<span>{{ $t("AppointmentFormTable.SDT") }}: </span
								>{{ customer.customer && customer.customer.phone }}</div
							>
							<div>
								<span>{{ $t("AppointmentFormTable.Birthday") }}: </span
								>{{ customer.customer && customer.customer.birthYear }}</div
							>
						</td>
						<td scope="row" class="text-pre-wrap appt-table__request"
							><template v-if="customer.requestedServices"
								><div
									v-for="(request, index3) in customer.requestedServices"
									:key="`${request.id}-${index3}`"
									>{{ request.name }}</div
								></template
							></td
						>
						<td scope="row" class="appt-table__clinic">
							<router-link
								:to="{
									name: 'UpdateClinic',
									params: { id: customer.clinicId },
								}"
								target="_blank"
								class="link-icon link-redirect"
							>
								{{ customer.clinic && customer.clinic.name }}
							</router-link>
							<!-- <span v-else>{{ customer.clinic && customer.clinic.name }}</span> -->
						</td>
						<td scope="row" class="text-pre-wrap appt-table__appt">
							<div
								><span>{{ $t("AppointmentFormTable.Id_date") }}: </span>{{ customer.code }}
								<img v-if="!customer.isManualCreate" v-lazy="autoIcon" alt class="autoIcon" />
							</div>
							<div>
								<template v-if="isCompleteStatus(customer.status)">
									<span>{{ $t("AppointmentFormTable.ResultDate") }}: </span>
									{{ moment(customer.finishedDate).format("DD/MM/YYYY HH:MM") }}
								</template>
								<template v-else>
									<span>{{ $t("AppointmentFormTable.HourDate") }}: </span>
									{{ moment(customer.planedDate).format("DD/MM/YYYY HH:MM") }}
								</template>
							</div>
							<div
								><span>{{ $t("AppointmentFormTable.Note") }}: </span>{{ customer.note }}</div
							>
						</td>
						<td scope="row" :class="'fit'">
							<div :class="`${getStatusClass(customer.status)}`">
								{{ getStatus(customer.status) }}
							</div>
							<div v-if="!customer.isManualCreate">
								{{ $t("AppointmentFormTable.IsAutoCreate") }}
							</div>
							<div v-if="getLateDayNumber(customer)" class="text-danger">
								Đã muộn {{ getLateDayNumber(customer) }} ngày.
							</div>
						</td>
						<td scope="row" class="fit text-center actions">
							<router-link
								:to="{ name: 'AdminUpdateAppt', params: { id: customer.id } }"
								class="link-icon text-info"
								><span class="prefix-input d-inline-block"
									><font-awesome-icon :icon="['fas', 'eye']" :title="$t('Action.Edit')" /> </span
							></router-link>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="13" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTableAppointment>
	</div>
</template>

<script>
import {
	GET_APPOINTMENT_LIST,
	GET_CLINICS,
	GET_SERVICES,
	IMPORT_FILE,
	EXPORT_FILE,
} from "../store/action-types"
import {
	RESET_FILTERS_APPT_LIST,
	CHANGE_PROGRESS_BAR_MODAL_STATUS,
	RESET_DATA_INPUT_FILTERS,
} from "../store/mutation-types"
import { createNamespacedHelpers, mapState } from "vuex"
const { mapActions, mapMutations } = createNamespacedHelpers("serviceAppointment")
import { STATUS, USER_ROLE, IMPORT_STATUS } from "@/shared/plugins/constants"
import { APPT_STATUS } from "../../../shared/plugins/constants"
import phoneIcon from "@/assets/img/phone_icon.jpeg"
import autoIcon from "@/assets/img/auto_icon.png"
import moment from "moment"

export default {
	name: "AppointmentManagement",

	data() {
		return {
			headers: [
				{
					title: this.$t("AppointmentFormTable.Name"),
					class: "text-nowrap name appt-table__name",
				},
				{
					title: this.$t("AppointmentFormTable.Request"),
					class: "text-nowrap appt-table__request",
				},
				{
					title: this.$t("AppointmentFormTable.Clinic"),
					class: "text-nowrap name appt-table__clinic",
				},
				{
					title: this.$t("AppointmentFormTable.Appoint"),
					class: "text-nowrap appt-table__appt",
				},
				{
					title: this.$t("AppointmentFormTable.Status"),
					class: "text-nowrap",
				},
				{
					title: this.$t("CustomerManagement.Actions"),
					class: "fit text-center",
				},
			],

			paramsExport: {},
			phoneIcon,
			autoIcon,
		}
	},

	computed: {
		...mapState("serviceAppointment", [
			"appointment",
			"clinics",
			"services",
			"totalItems",
			"totalPages",
			"recentFilters",
			"importStatus",
		]),
		...mapState("authentication", ["currentUser"]),

		isSystemAdmin: function () {
			return this.currentUser?.role === USER_ROLE.SYSTEM_ADMIN
		},

		isImporting: function () {
			return [IMPORT_STATUS.VALIDATING, IMPORT_STATUS.IN_PROGRESS].includes(
				this.importStatus?.status,
			)
		},
	},

	created() {
		this.setData()
	},

	beforeDestroy() {
		if (!this.$route.path.includes("appointment")) this.RESET_FILTERS_APPT_LIST()

		this.RESET_DATA_INPUT_FILTERS()
	},

	methods: {
		...mapActions({
			GET_APPOINTMENT_LIST,
			GET_CLINICS,
			GET_SERVICES,
			IMPORT_FILE,
			EXPORT_FILE,
		}),
		...mapMutations({
			RESET_FILTERS_APPT_LIST,
			CHANGE_PROGRESS_BAR_MODAL_STATUS,
			RESET_DATA_INPUT_FILTERS,
		}),

		setData: async function () {
			await this.GET_CLINICS()
			await this.GET_SERVICES()
		},

		getStatus(status) {
			switch (status) {
				case STATUS.PENDING:
					return this.$t("AppointmentStatus.PENDING")
				case STATUS.CUSTOMER_CANCEL:
					return this.$t("AppointmentStatus.CUSTOMER_CANCEL")
				case STATUS.COMPLETE:
					return this.$t("AppointmentStatus.COMPLETE")
				default:
					return this.$t("AppointmentStatus.PENDING")
			}
		},

		getStatusClass(status) {
			return {
				[STATUS.PENDING]: "status--pending",
				[STATUS.COMPLETE]: "status--complete",
				[STATUS.CUSTOMER_CANCEL]: "status--cancel",
			}[status]
		},

		create() {
			this.$router.push({ name: "AdminCreateAppt" })
		},

		search(params = {}) {
			this.paramsExport = { ...params }
			this.GET_APPOINTMENT_LIST(params)
		},

		importFile(formData) {
			this.IMPORT_FILE(formData)
		},

		exportFile: async function () {
			await this.EXPORT_FILE(this.paramsExport)
		},

		toggleProgressBar(params = {}) {
			this.CHANGE_PROGRESS_BAR_MODAL_STATUS(params)
		},

		goToAppt(event, apptId) {
			if (event.target.localName === "td") {
				this.$router.push({ name: "AdminUpdateAppt", params: { id: apptId } })
			}
		},

		goToApptNewTab(event, apptId) {
			if (event.target.localName === "td") {
				let routeData = this.$router.resolve({
					name: "AdminUpdateAppt",
					params: { id: apptId },
				})
				window.open(routeData.href, "_blank")
			}
		},

		isCompleteStatus(status) {
			return APPT_STATUS.COMPLETE === status
		},
		getBackgroundColor(appointment) {
			const isLate = moment(appointment.planedDate).isBefore(moment(), "day")
			if (appointment.status === APPT_STATUS.PENDING && isLate) {
				return {
					backgroundColor: "rgba(224,238,124,0.61)",
				}
			}
		},
		getLateDayNumber(appointment) {
			const isLate = moment(appointment.planedDate).isBefore(moment(), "day")
			if (appointment.status === APPT_STATUS.PENDING && isLate) {
				return moment().diff(moment(appointment.planedDate), "day")
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.appt-table {
	// &__name {
	// 	min-width: 150px;
	// 	max-width: 250px;
	// }
	// &__request {
	// 	min-width: 125px;
	// 	max-width: 250px;
	// }
	// &__appt {
	// 	min-width: 150px;
	// 	max-width: 250px;
	// }
	// &__clinic {
	// 	min-width: 125px;
	// 	max-width: 150px;
	// }

	// &__name > div,
	// &__clinic,
	// &__request > div,
	// &__appt > div {
	// 	white-space: nowrap !important;
	// 	overflow: hidden;
	// 	text-overflow: ellipsis;
	// }

	&__name,
	&__request,
	&__appt {
		> div {
			margin-bottom: 0.5rem;
		}
	}

	tbody tr:hover {
		cursor: pointer;
	}
}

.status {
	&--pending {
		color: $color-sea-green;
	}
	&--complete {
		color: $color-primary;
	}
	&--cancel {
		color: $color-red;
	}
}

.logo {
	width: 24px;
	height: 24px;
}

.name-container {
	display: flex;
	gap: 5px;

	.contact-info {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.phoneIcon {
	width: 100%;
	max-width: 22px;
	height: auto;
}

.autoIcon {
	margin-bottom: 15px;
	width: 100%;
	max-width: 22px;
	height: auto;
}

input {
	height: 35px !important;
}

/deep/ .multiselect {
	font-size: 14px;
	&__single {
		font-size: 14px;
	}

	&__tag {
		margin-bottom: 0;
	}

	&__option {
		white-space: normal;
	}
}
</style>
